<template>
  <div class="waning-exceed">

    <warning-bar :tabpage="1"/>
  </div>
</template>

<script>
import {Toast} from "vant";
import {getWarningCustomer} from '@/api/warningPanel'
import WarningBar from "@/components/WarningBar";
export default {
  name: "WarningExceed",
  components: {Toast,WarningBar},
  data() {
    return {
      freshHeight: '',
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init()
  },
  methods: {
    init(){

    }
  }
}
</script>

<style scoped>

.waning-exceed{
  background: #FEFDFF;
}

</style>